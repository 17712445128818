import { APP_ENVS } from "@cyna/common/constants"
import { z } from "zod"

const validationSchema = z.object({
  app: z.object({
    itemsPerPage: z.number(),
    minItemsPerPage: z.number(),
    maxItemsPerPage: z.number(),
    durationBeforeRedirection: z.number(),
  }),
  env: z.object({
    isDevelopment: z.boolean(),
    isTest: z.boolean(),
    isProduction: z.boolean(),
    isCI: z.boolean(),
  }),
  security: z.object({
    session: z.object({
      storageTokenHeader: z.string(),
      sessionHeaderPrefix: z.string().default("Bearer"),
    }),
  }),
})
const data: z.input<typeof validationSchema> = {
  app: {
    itemsPerPage: 15,
    minItemsPerPage: 1,
    maxItemsPerPage: 100,
    // In seconds
    durationBeforeRedirection: 5,
  },
  env: {
    isDevelopment: process.env.NEXT_PUBLIC_APP_ENV === APP_ENVS.DEV,
    isTest: process.env.NEXT_PUBLIC_APP_ENV === APP_ENVS.TEST,
    isProduction: process.env.NEXT_PUBLIC_APP_ENV === APP_ENVS.PROD,
    isCI: Boolean(process.env.CI),
  },
  security: {
    session: {
      // Using X-CSRF-TOKEN header for storageToken as it's not very likely to be filtered by proxies.
      storageTokenHeader: "x-csrf-token",
      sessionHeaderPrefix: "Bearer",
    },
  },
}

export const commonConfig = (() => {
  try {
    return validationSchema.parse(data)
  } catch (err) {
    const { errors } = err as z.ZodError
    const formattedErrors = errors.map(
      ({ path, message }) => `\t- ${path.join(".")}: ${message}\n`,
    )

    throw new Error(`invalid config\n${formattedErrors.join("")}`)
  }
})()
